var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tabs',{directives:[{name:"scroll",rawName:"v-scroll",value:(_vm.scrolled),expression:"scrolled"}],staticClass:"mx-auto tabs-border-bottom tabs-border-top",class:[
    _vm.$vuetify.breakpoint.smAndDown ? 'tabs-hidden' : '',
    _vm.pageScrolled ? 'elevation-4' : ''
  ],staticStyle:{"max-width":"1440px"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider',{attrs:{"color":"blue"}}),_vm._l((_vm.mainTabs),function(tb){return [(tb.cardText)?_c('v-menu',{key:tb.target || (tb.to && (tb.to.name || tb.to.path)),attrs:{"open-on-hover":"","close-on-click":false,"open-on-click":false,"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('div',_vm._g({style:(tb.cardText && 'cursor: default; pointer-events: auto;'),on:{"click":function($event){$event.stopPropagation();}}},on),[_c('v-tab',{class:{
              'tabs-hidden': _vm.$vuetify.breakpoint.smAndDown,
              'pr-2': tb.badgeText
            },staticStyle:{"height":"100%"},attrs:{"data-cy":(_vm.page + "-" + (tb.target) + "-tab"),"href":tb.target ? ("#" + (tb.target)) : null,"to":tb.to ? tb.to : null,"disabled":tb.disabled,"hidden":tb.hidden}},[_c('v-icon',{attrs:{"left":"","size":tb.iconSize || 'medium'}},[_vm._v(" "+_vm._s(tb.icon)+" ")]),_vm._v(" "+_vm._s(tb.name)+" "),(tb.badgeText)?_c('v-badge',{staticClass:"text-none",attrs:{"color":"codePink","content":tb.badgeText,"bottom":"","inline":""}}):_vm._e()],1)],1)]}}],null,true)},[(tb.cardText)?_c('v-card',{staticClass:"pa-0",attrs:{"tile":"","max-width":"320"}},[_c('v-card-title',[_c('v-badge',{attrs:{"color":"codePink","content":tb.badgeText,"bottom":"","bordered":"","inline":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(tb.icon))]),_vm._v(" "+_vm._s(tb.name)+" ")],1)],1),_c('v-card-text',[_c('div',[_vm._v(" "+_vm._s(tb.cardText)+" "),_c('ExternalLink',{attrs:{"href":tb.cardLink}},[_vm._v(_vm._s(tb.cardLinkText))]),_vm._v("! ")],1)])],1):_vm._e()],1):_c('v-tab',{key:tb.target || (tb.to && (tb.to.name || tb.to.path)),class:{
        'tabs-hidden': _vm.$vuetify.breakpoint.smAndDown,
        'pr-2': tb.badgeText
      },attrs:{"data-cy":(_vm.page + "-" + (tb.target) + "-tab"),"href":tb.target ? ("#" + (tb.target)) : null,"to":tb.to ? tb.to : null,"disabled":tb.disabled}},[_c('v-icon',{attrs:{"left":"","size":tb.iconSize || 'medium'}},[_vm._v(_vm._s(tb.icon))]),_vm._v(" "+_vm._s(tb.name)+" "),(tb.badgeText)?_c('v-badge',{staticClass:"text-none",class:{ disabled: tb.disabled },attrs:{"color":tb.badgeColor || 'accentPink',"content":tb.badgeText,"bottom":"","inline":""}}):_vm._e()],1)]}),_c('v-spacer'),_vm._l((_vm.secondaryTabs),function(tb){return _c('v-tab',{key:tb.target,class:_vm.$vuetify.breakpoint.smAndDown ? 'tabs-hidden' : '',attrs:{"data-cy":(_vm.page + "-" + (tb.target) + "-tab"),"href":("#" + (tb.target)),"disabled":tb.disabled,"hidden":tb.hidden}},[_c('v-icon',{attrs:{"left":"","size":tb.iconSize || 'medium'}},[_vm._v(_vm._s(tb.icon))]),_vm._v(" "+_vm._s(tb.name)+" ")],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }